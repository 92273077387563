<template>
  <div class="q-ma-md">
    <q-card square flat>
      <q-toolbar class="text-light-blue">
        <strong>物料信息</strong>
        <q-space />
      </q-toolbar>
      <q-separator />
      <!-- 搜索 -->
      <q-card-section>
        <q-form class="row items-center q-gutter-md">
          <q-input filled dense v-model="formData.where" placeholder="输入物料编码 / 物料名称 / 型号查询" style="width:250px" />
          <q-btn label="搜索" color="primary" icon="fas fa-search" @click="onSearch" />
        </q-form>
      </q-card-section>
      <!-- mobile  -->
      <q-card-section v-if="$q.platform.is.mobile">
        <mobile-list />
      </q-card-section>
      <!-- PC -->
      <q-card-section v-else>
        <base-table :tableSet="tableSet" style="height:447px" />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { useQuasar } from "quasar";
import MobileList from "@/components/pages/client-material/mobile-list.vue";
import BaseTable from "@/components/pieces/table/myTable";
import { useStore } from "vuex";
import { ref, computed, watch, provide } from "vue";
export default {
  components: { BaseTable, MobileList },
  setup() {
    const $q = useQuasar();
    const store = useStore();
    let formData = ref({ where: "" });

    let tableSet = computed(
      () => store.getters["client_material/material_source"]
    );
    function onSearch() {
      tableSet.value.pagination.page = 1
      init_data();
    }

    function init_data() {
      (formData.value.page = tableSet.value.pagination.page),
        (formData.value.rowsPerPage = tableSet.value.pagination.rowsPerPage),
        store.dispatch("client_material/init_material_data", formData.value);
    }
    //移动端  list数据

    provide("tableSource", tableSet.value);

    watch(
      [
        () => tableSet.value.pagination.rowsPerPage,
        () => tableSet.value.pagination.page,
      ],
      (v, v0) => {
        init_data();
      },
      { deep: true }
    );
    if (!$q.platform.is.mobile) {
      init_data();
    }

    return {
      tableSet,
      formData,
      onSearch,
      init_data,
    };
  },
};
</script>
<style scoped>
</style>