<template>
  <q-card style="background-color:#F6F6F6;" v-if="tableSource && tableSource.data && tableSource.data.length">
    <q-list class="block-item"
            v-for="item in tableSource.data"
            :key="item.FNumber"
            style="margin-bottom: 50px"
    >
      <q-card>
        <q-item>
        <span style="border-bottom: #9e9e9e 2px solid; width: 100%;font-weight: bolder;">
          {{ item.FNumber }}
        </span>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              销售单价：
              <span style="font-size: 18px; font-weight: bold; color: #00a2d4;">￥{{
                  formval(item.FSalePrice)
                }}
            </span>
            </q-item-label>
          </q-item-section>

        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              物料名称：
              <span style="font-size: 18px;">{{
                  item.FName
                }}
            </span>
            </q-item-label>
          </q-item-section>

        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              规格型号：
              <span style="font-size: 18px;">{{
                  item.FModel
                }}
            </span>
            </q-item-label>
          </q-item-section>

        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              库存数量：
              <span style="font-size: 18px;">{{
                  item.FQty
                }}
            </span>
            </q-item-label>
          </q-item-section>

        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              锁库数量：
              <span style="font-size: 18px;">{{
                  item.FQtyLock
                }}
            </span>
            </q-item-label>
          </q-item-section>

        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              可用数量：
              <span style="font-size: 18px;">{{
                  item.AVQty
                }}
            </span>
            </q-item-label>
          </q-item-section>

        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              采购在途：
              <span style="font-size: 18px;">{{
                  item.FStockAwaitQty
                }}
            </span>
            </q-item-label>
          </q-item-section>

        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              销售未出库：
              <span style="font-size: 18px;">{{
                  item.FNOutStockQty
                }}
            </span>
            </q-item-label>
          </q-item-section>

        </q-item>


      </q-card>

    </q-list>
  </q-card>
</template>
<script>
import {computed, inject} from "vue";

export default {
  name: "mobile-list",
  setup() {
    let tableSource = computed(() => {
      return inject("tableSource")
    })
    console.log(tableSource,'////')

    function formval(v) {
      v = String(v)

      if (v.indexOf(".")!= -1) {
        let n = v.split(".")[0]
        let r = v.split(".")[1]
        if (r.length < 4) {
          while (r.length < 4) {
            r = r + "0"
          }
        }
        return n + "." + r
      } else {
        return v + ".0000"
      }
    }

    return {
      tableSource,formval,
    }
  }
}
</script>
