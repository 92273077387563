<script>
import { ref, inject, toRef, computed, nextTick } from "vue";
import { useStore } from "vuex";
export default {
  name: "purchase-order",
  props: ["tableSet"],
  setup(props, context) {
    const myRef = ref(null);
    let tableSet = toRef(props.tableSet);
    if (!tableSet.value) {
      tableSet = computed(() => {
        return inject("tableSet");
      });
    }
    let source_pages = computed(() => {
      let tot = tableSet.value.pagination.rowsNumber
        ? tableSet.value.pagination.rowsNumber
        : 0;
      let rowpage = parseInt(tableSet.value.pagination.rowsPerPage);
      let pages = parseInt((tot - 1 + rowpage) / rowpage);
      return pages;
    });
    let dense = ref(false);
    let visibleColumns = ref([]);
    let showColumns = computed(() => {
      if (tableSet.value.columns && tableSet.value.columns.length > 0) {
        let show = [];
        tableSet.value.columns.forEach((it) => {
          show.push({ check: true, name: it.name, label: it.label });
        });
        return show;
      } else {
        return [];
      }
    });
    selectCol();
    function selectCol(name = null) {
      visibleColumns.value = [];
      showColumns.value.forEach((it) => {
        if (name && it.name === name) {
          it.check = !it.check;
        }
        if (it.check) {
          visibleColumns.value.push(it.name);
        }
      });
    }
    function tool_click(item) {
      context.emit("tool_click", item);
    }
    function handleClick(props) {
      console.log(props, "p////");
      // pasteRow.value = props.rowIndex;
      // pasteCol.value = props.col.name;
      // 排他思想,所有的都置为false,点谁再给谁赋值
      tableSet.value.data.map((item) => {
        item.rowKey = null;
      });
      props.row.rowKey = props.rowIndex;
      props.row.colKey = props.col.name;
      if (props.col.edit) {
        nextTick(() => {
          myRef.value.focus();
        });
      }
    }
    function onBlur() {
      tableSet.value.data.map((item) => {
        item.rowKey = null;
        item.colKey = null;
      });
    }
    function rowClick(e,row){
      context.emit('rowClick',row)
    }
    let default_pagination = ref({ rowsNumber: 0 });
    return {
      tableSet,
      default_pagination,
      source_pages,
      dense,
      showColumns,
      visibleColumns,
      selectCol,
      tool_click,
      handleClick,
      onBlur,
      myRef,
      rowClick
    };
  },
};
</script>

<template>
  <div>
    <!-- 可以编辑的表格        separator='cell' -->
    <q-table v-if="tableSet.edit && tableSet.selection" :rows="tableSet.data" :row-key="tableSet.rowkey" square flat
      color="primary" virtual-scroll :columns="tableSet.columns" :loading="tableSet.loading"
      :selection="tableSet.selection" v-model:selected="tableSet.selected" no-data-label="无可用数据"
      :rows-per-page-options="[1000000]" hide-pagination :dense="dense" :visible-columns="visibleColumns"
      >
      <template v-slot:body-cell="props">
        <q-td :props="props" @click="handleClick(props)">
          <span
            v-if="props.row.rowKey != props.rowIndex || props.row.colKey != props.col.name || !props.col.edit">{{props.value=='undefined'?'': props.value}}</span>
          <q-input v-if="props.row.rowKey == props.rowIndex &&props.row.colKey == props.col.name && props.col.edit"
            style="width:100px" v-model="tableSet.data[props.rowIndex][props.col.name]" dense outlined ref='myRef'
            @blur="onBlur" />
        </q-td>
      </template>
      <!-- 具体单元格插槽 可以设置某个字段的颜色 -->
      <template #body-cell-apiStatus="props">
           <q-td  :props="props">
            <q-badge color="green"  v-if="props.row.apiStatus == 1">
             成功
            </q-badge>
            <q-badge color="red" v-if="props.row.apiStatus == 0">
             失败
            </q-badge>
          </q-td>
      </template>

      <template v-slot:top="props" v-if="tableSet.tools&&tableSet.tools.show_toolbar">
        <q-toolbar class="bg-grey-2">
          <span class="q-ml-sm" v-if="tableSet.tools.title">{{ tableSet.tools.title }}</span>
          <q-space />
          <div class="q-gutter-sm text-right">
            <q-btn v-show="tableSet.tools&&tableSet.tools.btns.length > 0" v-for="item in tableSet.tools.btns"
              :key="item.index" rounded flat dense size="md" :icon="item.icon" color="primary"
              @click="tool_click(item)">
              <q-tooltip v-if="item.name">{{item.desc? item.desc: item.name[0]}}</q-tooltip>
            </q-btn>
            <q-btn rounded flat dense size="md" icon="fas fa-columns" color="primary">
              <q-tooltip>设置显示列</q-tooltip>
              <q-menu :offset="[0, 12]">
                <q-list dense>
                  <q-item clickable v-for="(column, index) in showColumns" :key="index" :active="column.check"
                    @click="selectCol(column.name)">
                    <q-item-section>{{ column.label }}</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
            <q-btn rounded flat dense size="md" icon="fas fa-table" color="primary" @click="dense=!dense">
              <q-tooltip>密集表格</q-tooltip>
            </q-btn>
            <q-btn rounded color="primary" flat round dense
              :icon="props.inFullscreen ? 'fas fa-compress' : 'fas fa-expand'" @click="props.toggleFullscreen">
              <q-tooltip>全屏</q-tooltip>
            </q-btn>
          </div>
        </q-toolbar>
      </template>

      <!-- 表头插槽 可以设置表头样式 必填项 -->
      <template v-slot:header="props">
        <q-tr :props="props">
          <!-- 有选择框时候,占位用,勿删 -->
          <q-th></q-th>
          <q-th v-for="col in props.cols" :key="col.name" :props="props">
             <span v-if="col.required" style="color:red">*</span>
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>

    </q-table>
    <!-- 有选择框的表格 -->
    <q-table v-else-if="tableSet.selection" :rows="tableSet.data" :row-key="tableSet.rowkey" square flat color="primary"
      virtual-scroll :columns="tableSet.columns" :loading="tableSet.loading" :selection="tableSet.selection"
      v-model:selected="tableSet.selected" no-data-label="无可用数据" v-model:pagination="default_pagination" hide-pagination
      @row-click ="rowClick"
      :dense="dense" :visible-columns="visibleColumns">
      <template v-slot:body-cell="props">
        <q-td :props="props">
          <q-icon v-if="typeof(props.value)=='boolean'" :name="props.value?'fas fa-check-circle':''"
            :class="props.value?'text-positive':''" size="xs" />
          <span v-else>{{ props.value }}</span>
        </q-td>
      </template>
      <template v-slot:top="props" v-if="tableSet.tools&&tableSet.tools.show_toolbar">
        <q-toolbar class="bg-grey-2">
          <span class="q-ml-sm" v-if="tableSet.tools.title">{{ tableSet.tools.title }}</span>
          <q-space />
          <div class="q-gutter-sm text-right">
            <q-btn v-show="tableSet.tools&&tableSet.tools.btns.length > 0" v-for="item in tableSet.tools.btns"
              :key="item.index" rounded flat dense size="md" :icon="item.icon" color="primary"
              @click="tool_click(item)">
              <q-tooltip v-if="item.name">{{item.desc? item.desc: item.name[0]}}</q-tooltip>
            </q-btn>
            <q-btn rounded flat dense size="md" icon="fas fa-columns" color="primary">
              <q-tooltip>设置显示列</q-tooltip>
              <q-menu :offset="[0, 12]">
                <q-list dense>
                  <q-item clickable v-for="(column, index) in showColumns" :key="index" :active="column.check"
                    @click="selectCol(column.name)">
                    <q-item-section>{{ column.label }}</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
            <q-btn rounded flat dense size="md" icon="fas fa-table" color="primary" @click="dense=!dense">
              <q-tooltip>密集表格</q-tooltip>
            </q-btn>
            <q-btn rounded color="primary" flat round dense
              :icon="props.inFullscreen ? 'fas fa-compress' : 'fas fa-expand'" @click="props.toggleFullscreen">
              <q-tooltip>全屏</q-tooltip>
            </q-btn>
          </div>
        </q-toolbar>
      </template>

      <template v-slot:bottom="scope" v-if="tableSet.pagination.rowsNumber">
        <div class="inline-block">
          <span class="inline-block text-body2">
            每页行数：
          </span>
          <span class="inline-block">
            <q-select v-model="tableSet.pagination.rowsPerPage" filled dense :options="[5,10,20,50,100]" />
          </span>
        </div>
        <div class="inline-block q-mx-sm items-center">
          <div class="inline-block text-body2">
            数据总行：
          </div>
          <div class="inline-block">
            <q-badge color="primary" align="middle" outline>
              {{tableSet.pagination.rowsNumber}}
            </q-badge>
          </div>
        </div>
        <q-pagination v-model="tableSet.pagination.page" color="primary" :max="source_pages" size="12px" :max-pages="5"
          :boundary-numbers="false" :boundary-links="true" class="float-right q-mt-sm q-px-sm"> </q-pagination>
      </template>
    </q-table>
    <!-- 没有选择框的表格 -->
    <q-table v-else :rows="tableSet.data" :row-key="tableSet.rowkey" square flat color="primary" virtual-scroll
      :columns="tableSet.columns" :loading="tableSet.loading" no-data-label="无可用数据"
      v-model:pagination="default_pagination" hide-pagination :dense="dense" :visible-columns="visibleColumns">
      <template v-slot:top="props" v-if="tableSet.tools&&tableSet.tools.show_toolbar">
        <q-toolbar class="bg-grey-2">
          <span class="q-ml-sm" v-if="tableSet.tools.title">{{ tableSet.tools.title }}</span>
          <q-space />
          <div class="q-gutter-sm text-right">
            <q-btn v-show="tableSet.tools&&tableSet.tools.btns.length > 0" v-for="item in tableSet.tools.btns"
              :key="item.index" rounded flat dense size="md" :icon="item.icon" color="primary"
              @click="tool_click(item)">
              <q-tooltip v-if="item.name">{{item.desc? item.desc: item.name[0]}}</q-tooltip>
            </q-btn>
            <q-btn rounded flat dense size="md" icon="fas fa-columns" color="primary">
              <q-tooltip>设置显示列</q-tooltip>
              <q-menu :offset="[0, 12]">
                <q-list dense>
                  <q-item clickable v-for="(column, index) in showColumns" :key="index" :active="column.check"
                    @click="selectCol(column.name)">
                    <q-item-section>{{ column.label }}</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
            <q-btn rounded flat dense size="md" icon="fas fa-table" color="primary" @click="dense=!dense">
              <q-tooltip>密集表格</q-tooltip>
            </q-btn>
            <q-btn rounded color="primary" flat round dense
              :icon="props.inFullscreen ? 'fas fa-compress' : 'fas fa-expand'" @click="props.toggleFullscreen">
              <q-tooltip>全屏</q-tooltip>
            </q-btn>
          </div>
        </q-toolbar>
      </template>
      <template v-slot:bottom="scope" v-if="tableSet.pagination.rowsNumber">
        <div class="inline-block">
          <span class="inline-block text-body2">
            每页行数：
          </span>
          <span class="inline-block"><q-select v-model="tableSet.pagination.rowsPerPage" filled dense
              :options="[5,10,20,50,100]" /></span>
        </div>
        <div class="inline-block q-mx-sm items-center">
          <div class="inline-block text-body2">
            数据总行：
          </div>
          <div class="inline-block">
            <q-badge color="primary" align="middle" outline>
              {{tableSet.pagination.rowsNumber}}
            </q-badge>
          </div>
        </div>
        <q-pagination v-model="tableSet.pagination.page" color="primary" :max="source_pages" size="12px" :max-pages="5"
          :boundary-numbers="false" :boundary-links="true" class="float-right q-mt-sm q-px-sm"> </q-pagination>
      </template>
    </q-table>

  </div>

</template>